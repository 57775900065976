<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Anggota : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- email -->
          <b-form-group label="Email" label-for="Email">
            <validation-provider #default="{ errors }" name="Subject">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Search email"
                  v-model="filter.orang_email"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- nama_lengkap -->
          <b-form-group label="Nama Lengkap" label-for="Nama Lengkap">
            <validation-provider #default="{ errors }" name="Subject">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Search nama lengkap"
                  v-model="filter.orang_nama_lengkap"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- npa_id -->
          <b-form-group label="NPA IDI" label-for="NPA IDI">
            <validation-provider #default="{ errors }" name="Subject">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Search npa id"
                  v-model="filter.orang_npa_idi"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- npa_masa_berlaku -->
          <b-form-group label="NPA Masa Berlaku" label-for="NPA Masa Berlaku">
            <validation-provider #default="{ errors }" name="Subject">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="example-datepicker"
                  v-model="filter.orang_npa_masa_berlaku"
                  class="mb-1"
                  type="date"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" class="mb-25">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="refreshPage()"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- data table -->

    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="showDetailAnggota(data.item)">
            <feather-icon icon="SettingsIcon" size="16" />
            <span class="align-middle ml-50">Detail</span>
          </b-dropdown-item>
          <b-dropdown-item @click="showModalListInvoice(data.item)">
            <feather-icon icon="DollarSignIcon" size="16" />
            <span class="align-middle ml-50">Lihat Invoice</span>
          </b-dropdown-item>
          <b-dropdown-item @click="setModalCreatePembayaran(data.item)">
            <feather-icon icon="DollarSignIcon" size="16" />
            <span class="align-middle ml-50">Pembayaran</span>
          </b-dropdown-item>
          <b-dropdown-item @click="showModalListReskip(data.item)">
            <feather-icon icon="BookmarkIcon" size="16" />
            <span class="align-middle ml-50">Rekomnedasi Izin Praktek</span>
          </b-dropdown-item>
          <b-dropdown-item @click="showModalListPerpanjanganKta(data.item)">
            <feather-icon icon="CreditCardIcon" size="16" />
            <span class="align-middle ml-50">Perpanjang KTA</span>
          </b-dropdown-item>
          <b-dropdown-item @click="showModalListMutasiKeluar(data.item)">
            <feather-icon icon="ArrowLeftCircleIcon" size="16" />
            <span class="align-middle ml-50">Mutasi Keluar</span>
          </b-dropdown-item>
          <b-dropdown-item @click="showModalEditAnggota(data.item)">
            <feather-icon icon="EditIcon" size="16" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="inactiveDataAnggota(data.item)">
            <feather-icon icon="XCircleIcon" size="16" />
            <span class="align-middle ml-50">Inactive</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteAnggota(data.item)">
            <feather-icon icon="XCircleIcon" size="16" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
          <b-dropdown-item @click="approveDataAnggota(data.item)">
            <feather-icon icon="CheckIcon" size="16" />
            <span class="align-middle ml-50">Setuju/aktifkan</span>
          </b-dropdown-item>
          <b-dropdown-item @click="validateAnggotaToPBIDI(data.item)">
            <feather-icon icon="GitCommitIcon" size="16" />
            <span class="align-middle ml-50">Validate PB IDI</span>
          </b-dropdown-item>
          <b-dropdown-item @click="modalSendEmail(data.item)">
            <feather-icon icon="SendIcon" size="16" />
            <span class="align-middle ml-50">Kirim Email</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>

    <!-- list data calon angggota-->
    <b-modal id="modal-detail-anggota" size="xl" hide-footer>
      <template #modal-title>Detail Data </template>
      <!-- Invoice Description: Total -->
      <table style="width: 100%">
        <b-table-simple
          hover
          caption-top
          responsive
          class="rounded-bottom mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th colspan="7" class="text-right">
                #{{ detailAnggota.id }} [{{ detailAnggota.orang_status }}] [{{
                  detailAnggota.orang_level
                }}]
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-th rowspan="20"> Data Diri </b-th>
              <b-th class="text-right"> Anggota Wilayah : </b-th>
              <b-td>{{ detailAnggota.orang_level_wilayah }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Foto : </b-th>
              <b-td v-if="detailAnggota.orang_file_photo_resmi != null">
                <img
                  v-if="!detailAnggota.orang_file_photo_resmi.includes('https')"
                  :src="
                    'http://www.idijakpus.or.id/uploads/reg/pasphoto/' +
                      detailAnggota.id +
                      '/' +
                      detailAnggota.orang_file_photo_resmi
                  "
                  alt="foto"
                  width="200"
                  height="300"
                />
                <b-td v-else>
                  <img
                    :src="detailAnggota.orang_file_photo_resmi"
                    alt="foto"
                    width="200"
                    height="300"
                  />
                </b-td>
              </b-td>
              <b-td v-else>{{ "foto tidak ada" }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> NPA : </b-th>
              <b-td> {{ detailAnggota.orang_npa_idi }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Masa Berlaku NPA : </b-th>
              <b-td> {{ detailAnggota.orang_npa_masa_berlaku }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> File KTA : </b-th>
              <b-td v-if="detailAnggota.orang_npa_file != null">
                <a
                  v-if="!detailAnggota.orang_npa_file.includes('https')"
                  :href="
                    'http://www.idijakpus.or.id/uploads/reg/orang_npa_file/' +
                      detailAnggota.id +
                      '/' +
                      detailAnggota.orang_npa_file
                  "
                  alt="pdf"
                  target="_blank"
                  >Tampilkan File</a
                >
                <a
                  v-else
                  :href="detailAnggota.orang_npa_file"
                  alt="pdf"
                  target="_blank"
                  >Lihat File NPA</a
                >
              </b-td>
              <b-td v-else>{{ "file tidak ada" }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Nama Lengkap Tanpa Gelar : </b-th>
              <b-td> {{ detailAnggota.orang_nama_lengkap }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Gelar Depan : </b-th>
              <b-td> {{ detailAnggota.orang_gelar_depan }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Gelar Belakang : </b-th>
              <b-td> {{ detailAnggota.orang_gelar_belakang }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Tempat Lahir : </b-th>
              <b-td> {{ detailAnggota.orang_tempat_lahir }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Tanggal Lahir : </b-th>
              <b-td> {{ detailAnggota.orang_tanggal_lahir }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Jenis Kelamin : </b-th>
              <b-td> {{ detailAnggota.orang_jenis_kelamin }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Jenis Kartu Identitas : </b-th>
              <b-td> {{ detailAnggota.orang_kartu_id_jenis }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Nomor Identitas : </b-th>
              <b-td> {{ detailAnggota.orang_kartu_id_nomor }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> File Identitas : </b-th>
              <b-td v-if="detailAnggota.orang_kartu_id_file != null">
                <a
                  v-if="!detailAnggota.orang_kartu_id_file.includes('https')"
                  :href="
                    'http://www.idijakpus.or.id/uploads/reg/orang_kartu_id_file/' +
                      detailAnggota.id +
                      '/' +
                      detailAnggota.orang_kartu_id_file
                  "
                  alt="pdf"
                  target="_blank"
                  >Tampilkan File</a
                >
                <a
                  v-else
                  :href="detailAnggota.orang_kartu_id_file"
                  alt="pdf"
                  target="_blank"
                  >Lihat File Identitas</a
                >
              </b-td>
              <b-td v-else>{{ "file tidak ada" }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Status Pernikahan : </b-th>
              <b-td> {{ detailAnggota.orang_pernikahan_status }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Nama Pasangan : </b-th>
              <b-td> {{ detailAnggota.orang_pernikahan_nama_pasangan }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Alamat Tempat Tinggal : </b-th>
              <b-td> {{ detailAnggota.orang_alamat_rumah }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Telpon : </b-th>
              <b-td> {{ detailAnggota.orang_nomor_telpon }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Handphone : </b-th>
              <b-td> {{ detailAnggota.orang_nomor_hp }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right"> Email : </b-th>
              <b-td> {{ detailAnggota.orang_email }}</b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td colspan="7" variant="secondary" class="text-right"> </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </table>
    </b-modal>

    <!-- list invoices and verify invoices from calon anggota -->
    <b-modal id="modal-list-invoices" size="xl" hide-footer>
      <template #modal-title>Invoices </template>
      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="invoices"
        :fields="fieldsInvoices"
        responsive
        class="mb-0"
        @row-selected="onRowSelected"
      >
        <template #cell(document)="data">
          <a
            :href="data.item.document"
            v-if="data.item.document"
            target="_blank"
            >Open</a
          >
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
        <template #cell(action)="data">
          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="showVerifyInvoices(data.item)">
              <feather-icon icon="SettingsIcon" size="16" />
              <span class="align-middle ml-50">Verify</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <div></div>
        <!--pagination -->
        <div class="demo-inline-spacing pt-0">
          <b-pagination
            v-model="currentPageInvoices"
            hide-goto-end-buttons
            :total-rows="rowsInvoices"
            :per-page="perPageInvoices"
          />
        </div>
      </b-card-body>
    </b-modal>

    <!-- list reksip -->
    <b-modal id="modal-list-reksip" size="xl" hide-footer>
      <template #modal-title> Rekomnedasi Izin Praktek </template>
      <!-- Invoice Description: Total invoice data -->
      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="reskipes"
        :fields="list_reskipes"
        responsive
        class="mb-2"
        @row-selected="onRowInvoice"
      >
        <template #cell(document)="data">
          <a
            :href="data.item.document"
            v-if="data.item.document"
            target="_blank"
            >Open</a
          >
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <div></div>
        <!--pagination -->
        <div class="demo-inline-spacing pt-0">
          <b-pagination
            v-model="currentPageReskipes"
            hide-goto-end-buttons
            :total-rows="rowsReskipes"
            :per-page="perPageReskipes"
          />
        </div>
      </b-card-body>
    </b-modal>

    <!-- list pepanjangan kta -->
    <b-modal id="modal-list-perpanjangan-kta" size="xl" hide-footer>
      <template #modal-title> Perpanjang KTA </template>
      <!-- Invoice Description: Total invoice data -->
      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="perpanjanganktas"
        :fields="list_perpanjangan_ktas"
        responsive
        class="mb-2"
        @row-selected="onRowInvoice"
      >
        <template #cell(document)="data">
          <a
            :href="data.item.document"
            v-if="data.item.document"
            target="_blank"
            >Open</a
          >
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <div></div>
        <!--pagination -->
        <div class="demo-inline-spacing pt-0">
          <b-pagination
            v-model="currenPagePerpanjanganKtas"
            hide-goto-end-buttons
            :total-rows="rowsPerpanjanganKtas"
            :per-page="PerPagePerpanjanganKtas"
          />
        </div>
      </b-card-body>
    </b-modal>

    <!-- list mutasi keluar -->
    <b-modal id="modal-list-mutasi-keluar" size="xl" hide-footer>
      <template #modal-title> Mutasi Keluar </template>
      <!-- Invoice Description: Total invoice data -->
      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="mutasiKeluaras"
        :fields="list_mutasi_keluaras"
        responsive
        class="mb-2"
        @row-selected="onRowInvoice"
      >
        <template #cell(document)="data">
          <a
            :href="data.item.document"
            v-if="data.item.document"
            target="_blank"
            >Open</a
          >
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <div></div>
        <!--pagination -->
        <div class="demo-inline-spacing pt-0">
          <b-pagination
            v-model="currentPageMutasiKeluar"
            hide-goto-end-buttons
            :total-rows="rowsMutasiKeluaras"
            :per-page="perPageMutasiKeluar"
          />
        </div>
      </b-card-body>
    </b-modal>

    <!-- modal edit anggota -->
    <b-modal
      id="modal-edit-anggota"
      title="Edit Anggota"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <h4><b> I. Data Diri</b></h4>
        <br />
        <b-form-group label="Id" label-for="id_orang ">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="id_orang"
                v-model="detailEditAnggota.id"
                placeholder="Id Orang"
                readonly
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Status" label-for="Status">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="id_orang"
                v-model="detailEditAnggota.orang_status"
                placeholder="Orang Status"
                readonly
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Status Keanggotaan"
          label-for="Status Keanggotaan"
          disable
        >
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="id_orang"
                v-model="detailEditAnggota.orang_level"
                placeholder="Jenis Pendaftaran"
                readonly
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Pasfoto" label-for="file_passphoto">
          <b-form-file
            id="file-upload-passphoto"
            @change="handlerfilePassfoto($event)"
            v-model="detailEditAnggota.orang_file_photo_resmi"
            accept="image/*"
          />
          <th>PassFoto:</th>
          <td v-if="detailEditAnggota.orang_file_photo_resmi != null">
            <a
              v-if="!detailEditAnggota.orang_file_photo_resmi.includes('https')"
              :href="
                'http://www.idijakpus.or.id/uploads/reg/orang_file_photo_resmi/' +
                  detailEditAnggota.id +
                  '/' +
                  detailEditAnggota.orang_file_photo_resmi
              "
              alt="foto"
              target="_blank"
              >Tampilkan File</a
            >
            <a
              v-else
              :href="detailEditAnggota.orang_file_photo_resmi"
              alt="foto"
              target="_blank"
              >Tampilkan File</a
            >
          </td>
          <td v-else>{{ "file tidak ada" }}</td>
        </b-form-group>
        <b-form-group label="Nomor NPA" label-for="nomor_npa">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="nomor_npa"
                v-model="detailEditAnggota.orang_npa_idi"
                placeholder="nomor_npa"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Masa Berlaku NPA" label-for="masa_berlaku_npa">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="npa_berlaku"
                v-model="detailEditAnggota.orang_npa_masa_berlaku"
                class="mb-1"
                type="date"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="File KTA" label-for="file_kta">
          <b-form-file
            id="file-upload-kta"
            @change="handlerfileKTA($event)"
            v-model="detailEditAnggota.orang_npa_file"
            accept="image/*"
          />
          <th>File KTA:</th>
          <td v-if="detailEditAnggota.orang_npa_file != null">
            <a
              v-if="!detailEditAnggota.orang_npa_file.includes('https')"
              :href="
                'http://www.idijakpus.or.id/uploads/orang_npa_file/' +
                  detailEditAnggota.id +
                  '/' +
                  detailEditAnggota.orang_npa_file
              "
              alt="foto"
              target="_blank"
              >Tampilkan File</a
            >
            <a
              v-else
              :href="detailEditAnggota.orang_npa_file"
              alt="foto"
              target="_blank"
              >Tampilkan File</a
            >
          </td>
          <td v-else>{{ "file tidak ada" }}</td>
        </b-form-group>
        <b-form-group
          label="Nama Lengkap Tanpa Gelar "
          label-for="nama_lengkap_tanpa_gelar "
        >
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="nama_lengkap_tanpa_gelar"
                v-model="detailEditAnggota.orang_nama_lengkap"
                placeholder="Masa Berlaku Npa"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Gelar Depan " label-for="gelar_depan ">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="gelar_depan"
                v-model="detailEditAnggota.orang_gelar_depan"
                placeholder="gelar depan"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Gelar Belakang " label-for="gelar_belakang ">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="gelar_belakang"
                v-model="detailEditAnggota.orang_gelar_belakang"
                placeholder="gelar belakang"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Tempat Lahir " label-for="tempat_lahir ">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="tempat_lahir"
                v-model="detailEditAnggota.orang_tempat_lahir"
                placeholder="gelar belakang"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Tanggal Lahir " label-for="tanggal_lahir ">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="tanggal_lahir"
                v-model="detailEditAnggota.orang_tanggal_lahir"
                class="mb-1"
                type="date"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Jenis Kelamin" label-for="jenis_kelamin">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <v-select
                id="jenis_kelamin"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="option_jenis_kelamin"
                placeholder="jenis_kelamin.."
                v-model="detailEditAnggota.orang_jenis_kelamin"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Jenis Kartu Identitas"
          label-for="jenis_kartu_identitas"
        >
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <v-select
                id="jenis_kartu_identitas"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="option_jenis_kartu_identitas"
                placeholder="jenis_kartu_identitas.."
                v-model="detailEditAnggota.orang_kartu_id_jenis"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Nomor Identitas " label-for="nomor_identitas ">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="nomor_identitas"
                v-model="detailEditAnggota.orang_kartu_id_nomor"
                placeholder="nomor identitas"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="File Identitas" label-for="file_ktp">
          <b-form-file
            id="file-upload-ktp"
            @change="handlerfileKTP($event)"
            v-model="detailEditAnggota.orang_kartu_id_file"
            accept="image/*"
          />
          <th>File KTP:</th>
          <td v-if="detailEditAnggota.orang_kartu_id_file != null">
            <a
              v-if="!detailEditAnggota.orang_kartu_id_file.includes('https')"
              :href="
                'http://www.idijakpus.or.id/uploads/orang_kartu_id_file/' +
                  detailEditAnggota.id +
                  '/' +
                  detailEditAnggota.orang_kartu_id_file
              "
              alt="foto"
              target="_blank"
              >Tampilkan File</a
            >
            <a
              v-else
              :href="detailEditAnggota.orang_kartu_id_file"
              alt="foto"
              target="_blank"
              >Tampilkan File</a
            >
          </td>
          <td v-else>{{ "file tidak ada" }}</td>
        </b-form-group>
        <b-form-group label="Status Pernikahan" label-for="status_pernikahan">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <v-select
                id="status_pernikahan"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="option_status_pernikahan"
                placeholder="status_pernikahan.."
                v-model="detailEditAnggota.orang_pernikahan_status"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Nama Pasangan " label-for="nama_pasangan ">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="nama_pasangan"
                v-model="detailEditAnggota.orang_pernikahan_nama_pasangan"
                placeholder="nama_pasangan"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Alamat Tempat Tinggal "
          label-for="alamat_tempat_tinggal "
        >
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="alamat_tempat_tinggal"
                v-model="detailEditAnggota.orang_alamat_rumah"
                placeholder="alamat_tempat_tinggal"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Telpon " label-for="telpon ">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="telpon"
                v-model="detailEditAnggota.orang_nomor_telpon"
                placeholder="telpon"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Handphone " label-for="handphone ">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="handphone"
                v-model="detailEditAnggota.orang_nomor_hp"
                placeholder="handphone"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Email " label-for="email ">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="email"
                v-model="detailEditAnggota.orang_email"
                placeholder="email"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="editDataAnggota"
        >
          Simpan
        </b-button>
      </b-form>
    </b-modal>

    <!-- modal send message email -->
    <b-modal
      id="modal-send-email"
      size="xl"
      title="Send Email"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-form-group label="Email" label-for="Email">
          <b-form-input
            id="email"
            v-model="sendemail.orang_email"
            placeholder="Masukan Email"
            readonly
          />
        </b-form-group>
        <b-form-group label="Subject" label-for="Subject">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="email"
                v-model="sendemail.subject"
                placeholder="Masukan Subject Email"
                class="form-control-merge"
                :state="errors.length > 0 ? false : null"
                name="login-password"
                autocomplete="on"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Message" label-for="Message">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <quill-editor
                v-model="sendemail.message"
                :options="snowOptions"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="sendMessageEmail"
        >
          Kirim
        </b-button>
      </b-form>
    </b-modal>

    <!-- set pembayaran -->
    <b-modal
      id="modal-create-pembayaran"
      title="Create Pembayaran"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-form-group label="Id" label-for="id_orang ">
          <validation-provider #default="{ errors }" name="id_orang">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="id_orang"
                v-model="getPembayaran.id"
                placeholder="Id Orang"
                readonly
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="email" label-for="email ">
          <validation-provider #default="{ errors }" name="email">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="email"
                v-model="getPembayaran.orang_email"
                placeholder="email"
                readonly
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Nama Lengkap Tanpa Gelar"
          label-for="nama_lengkap_tanpa_gelar "
        >
          <validation-provider
            #default="{ errors }"
            name="nama_lengkap_tanpa_gelar"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="nama_lengkap_tanpa_gelar"
                v-model="getPembayaran.orang_nama_lengkap"
                placeholder="Id Orang"
                readonly
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Nama" label-for="nama-invoices">
          <validation-provider #default="{ errors }" name="nama-invoices">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="id_orang"
                v-model="getPembayaran.nama_pembayaran"
                placeholder="Nama"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Keterangan" label-for="keterangan">
          <validation-provider #default="{ errors }" name="keterangan">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="id_orang"
                v-model="getPembayaran.keterangan_pembayaran"
                placeholder="keterangan"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Nominal" label-for="nominal">
          <validation-provider #default="{ errors }" name="nominal">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="id_orang"
                v-model="getPembayaran.jumlah_pembayaran"
                placeholder="nominal"
                type="number"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Status" label-for="status_invoices">
          <validation-provider #default="{ errors }" name="status_invoices">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <v-select
                id="status_invoices"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="optionStatusInvoices"
                placeholder="status invoices.."
                v-model="getPembayaran.status_pembayaran"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="create"
          block
          :disabled="invalid"
          @click="createPembayaranAnggota"
        >
          Simpan
        </b-button>
      </b-form>
    </b-modal>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BButton,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BFormDatepicker,
  BTfoot,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BModal,
  BFormFile,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { quillEditor } from "vue-quill-editor";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BFormFile,
    BCardBody,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BBadge,
    vSelect,
    BFormDatepicker,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BCard,
    BCardText,
    BCardTitle,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BModal,
    BFormTextarea,
    quillEditor,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],

      //list anggota
      fields: [
        { key: "id", label: "id" },
        [{ key: "orang_nama_lengkap", label: "nama lengkap" }],
        [{ key: "orang_npa_idi", label: "NPA" }],
        [{ key: "orang_email", label: "email" }],
        [{ key: "orang_tempat_lahir", label: "tempat lahir" }],
        [{ key: "orang_tanggal_lahir", label: "tanggal lahir" }],
        [{ key: "orang_level_wilayah", label: "level" }],
        [{ key: "orang_nomor_hp", label: "nomor handphone" }],
        [{ key: "orang_status", label: "status" }],
        [{ key: "orang_npa_masa_berlaku", label: "npa masa berlaku" }],
        [{ key: "orang_ppds.reksip_kategori", label: "ppds" }],
        [{ key: "flags", label: "flag" }],
        [{ key: "created_at", label: "tanggal daftar" }],
        "action",
      ],
      items: [],

      // list reskip
      list_reskipes: [
        { key: "id", label: "id" },
        "reksip_kategori",
        "reksip_kategori_detail",
        "reksip_pend_file",
        "reksip_str_file",
        "reksip_nama_instansi",
        "reksip_alamat_instansi",
      ],
      reskipes: [],

      //invoices data table
      currentPageInvoices: 1,
      perPageInvoices: 25,
      rowsInvoices: 0,
      //reksip data table
      rowsReskipes: 0,
      currentPageReskipes: 1,
      perPageReskipes: 10,
      //perpanjangan kta table
      rowsPerpanjanganKtas: 0,
      currenPagePerpanjanganKtas: 1,
      PerPagePerpanjanganKtas: 10,
      //mutasi keluar table
      rowsMutasiKeluaras: 0,
      currentPageMutasiKeluar: 1,
      perPageMutasiKeluar: 10,

      //list invoices
      invoices: [],
      fieldsInvoices: [
        { key: "id", label: "id" },
        [{ key: "invoice_file", label: "file" }],
        [{ key: "invoice_name", label: "nama" }],
        [{ key: "invoice_jumlah", label: "jumlah" }],
        [{ key: "invoice_status", label: "status" }],
        "action",
      ],
      //list mutasi keluar
      list_mutasi_keluaras: [{ key: "id", label: "id" }],
      mutasiKeluaras: [],
      //list perpanjangan kta
      list_perpanjangan_ktas: [{ key: "id", label: "id" }],
      perpanjanganktas: [],

      sendemail: {
        subject: "",
        orang_email: "",
        message: "",
      },
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],

      detailAnggota: {},
      //filter upload foto
      filePassfoto: null,
      fileKta: null,
      fileKtp: null,

      //get pembayaran
      getPembayaran: {},

      //edit data anggota
      detailEditAnggota: {},

      //filter(search) data anggota fields
      filter: {
        orang_npa_idi: "",
        orang_email: "",
        orang_nama_lengkap: "",
        orang_npa_masa_berlaku: "",
      },

      solidColor: [{ bg: "danger", title: "Danger card title" }],

      //option select for jenis_kelamin,kartu_jenis and status pernikahan
      option_jenis_kelamin: ["Wanita", "Pria"],
      option_jenis_kartu_identitas: ["KTP", "Paspor", "SIM"],
      option_status_pernikahan: ["Belum Menikah", "Sudah Menikah", "Cerai"],
      optionStatusInvoices: ["belum-dibayar", "sudah-dibayar"],
      snowOptions: {
        theme: "snow",
      },
    };
  },

  computed: {},
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        // this.getPendidikan()
        this.getAnggota();
      } else {
        this.currentPage = 1;
      }
    },

    showDetailAnggota(item) {
      this.getDetailAnggota(item);
      this.$bvModal.show("modal-detail-anggota");
    },

    showModalEditAnggota(item) {
      this.detailEditAnggota = item;
      this.$bvModal.show("modal-edit-anggota");
    },

    showModalListInvoice(id) {
      this.getListInvoice(id);
      this.$bvModal.show("modal-list-invoices");
    },

    setModalCreatePembayaran(item) {
      this.getPembayaran = item;
      this.$bvModal.show("modal-create-pembayaran");
    },

    showModalListReskip(id) {
      this.getListReksip(id);
      this.$bvModal.show("modal-list-reksip");
    },

    showModalListPerpanjanganKta(id) {
      this.getListPerpanjanganKta(id);
      this.$bvModal.show("modal-list-perpanjangan-kta");
    },

    showModalListMutasiKeluar(id) {
      this.getListMutasiKeluar(id);
      this.$bvModal.show("modal-list-mutasi-keluar");
    },

    modalSendEmail(item) {
      this.$bvModal.show("modal-send-email");
      this.sendemail = item;
    },

    showVerifyInvoices(item) {
      this.$router.push(`invoice/${item.id}`);
    },

    // handler upload foto

    handlerfilePassfoto(e) {
      const { files } = e.target;
      if (files.length) {
        this.createImagefilePassfoto(files[0], (result) => {
          this.filePassfoto = result;
        });
      }
    },

    createImagefilePassfoto(file, cb) {
      const reader = new FileReader();

      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    // end handler upload foto

    // handler upload file kta

    handlerfileKTA(e) {
      const { files } = e.target;
      if (files.length) {
        this.createImgFileKTA(files[0], (result) => {
          this.fileKta = result;
        });
      }
    },

    createImgFileKTA(file, cb) {
      const reader = new FileReader();

      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    // end handler upload file kta

    // handler upload file ktp

    handlerfileKTP(e) {
      const { files } = e.target;
      if (files.length) {
        this.createImgFileKTP(files[0], (result) => {
          this.fileKtp = result;
        });
      }
    },

    createImgFileKTP(file, cb) {
      const reader = new FileReader();

      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    // end handler upload file ktp

    async getAnggota() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const param = {
          limit: this.perPage,
          page: this.currentPage,
          // filter data by search anggota
          orang_npa_idi: this.filter.orang_npa_idi,
          orang_nama_lengkap: this.filter.orang_nama_lengkap,
          orang_email: this.filter.orang_email,
          orang_npa_masa_berlaku: this.filter.orang_npa_masa_berlaku,
        };
        const { data } = await API.anggota.list(param);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data anggota");
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async getDetailAnggota(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const { data } = await API.anggota.detail(ids.id);
        this.detailAnggota = data;

        this.handleMsgSuccess("Berhasil Menampilkan Detail Anggota");
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async getListInvoice(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        let { data } = await API.anggota.invoice(ids.id);
        this.invoices = data.data;
        this.rowsInvoices = data.total;
        this.handleMsgSuccess("sukses menampilkan list invoice");
      } catch (error) {
        this.handleMsgErrors(
          error,
          "tidak dapat menampilkan invoice silahkan coba lagi"
        );
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async getListReksip(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id = ids.id;

        const body = {
          // config new page
          limit: this.perPageReskipes,
          page: this.currentPageReskipes,
        };
        const { data } = await API.anggota.reskip(id, body);
        this.reskipes = data.data;
        this.handleMsgSuccess("Berhasil menampilkan data reskip");
      } catch {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async getListPerpanjanganKta(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id = ids.id;

        const body = {
          // config new page
          limit: this.PerPagePerpanjanganKtas,
          page: this.currenPagePerpanjanganKtas,
        };

        const { data } = await API.anggota.perpanjangan_kta(id, body);
        this.perpanjanganktas = data.data;
        this.handleMsgSuccess("Berhasil menampilkan data perpanjangan kta");
      } catch {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async getListMutasiKeluar(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id = ids.id;

        const body = {
          // config new page
          limit: this.perPageMutasiKeluar,
          page: this.currentPageMutasiKeluar,
        };

        const { data } = await API.anggota.list_mutasi_keluar(id, body);
        this.mutasiKeluaras = data.data;
        this.handleMsgSuccess("Berhasil menampilkan data list mutasi keluar");
      } catch {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async inactiveDataAnggota(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text:
            "Data Anggota Ini Akan Di Inactive, Apakah Anda Ingin Melanjutkan ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Inactive!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((data) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (data.value) {
              return API.anggota.inactive(ids.id);
            }
            return false;
          })
          .then((data) => {
            if (data) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Inactive data anggota",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async editDataAnggota() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text:
            "Data Anggota Ini Akan Di Edit, Pastikan data yang di edit sudah sesuai dengan data yang sesungguhnya",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Edit!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.anggota.edit({
                id: this.detailEditAnggota.id,
                orang_status: this.detailEditAnggota.orang_status,
                orang_level: this.detailEditAnggota.orang_level,
                orang_level_wilayah: this.detailEditAnggota.orang_level_wilayah,
                orang_npa_idi: this.detailEditAnggota.orang_npa_idi,
                orang_npa_masa_berlaku: this.detailEditAnggota
                  .orang_npa_masa_berlaku,
                orang_nama_lengkap: this.detailEditAnggota.orang_nama_lengkap,
                orang_gelar_depan: this.detailEditAnggota.orang_gelar_depan,
                orang_gelar_belakang: this.detailEditAnggota
                  .orang_gelar_belakang,
                orang_tempat_lahir: this.detailEditAnggota.orang_tempat_lahir,
                orang_tanggal_lahir: this.detailEditAnggota.orang_tanggal_lahir,
                orang_jenis_kelamin: this.detailEditAnggota.orang_jenis_kelamin,
                orang_kartu_id_jenis: this.detailEditAnggota
                  .orang_kartu_id_jenis,
                orang_kartu_id_nomor: this.detailEditAnggota
                  .orang_kartu_id_nomor,
                orang_pernikahan_status: this.detailEditAnggota
                  .orang_pernikahan_status,
                orang_pernikahan_nama_pasangan: this.detailEditAnggota
                  .orang_pernikahan_nama_pasangan,
                orang_alamat_rumah: this.detailEditAnggota.orang_alamat_rumah,
                orang_nomor_telpon: this.detailEditAnggota.orang_nomor_telpon,
                orang_nomor_hp: this.detailEditAnggota.orang_nomor_hp,
                orang_email: this.detailEditAnggota.orang_email,
                orang_file_photo_resmi:
                  this.filePassfoto != null
                    ? this.filePassfoto
                    : this.detailEditAnggota.orang_file_photo_resmi,
                orang_npa_file:
                  this.fileKta != null
                    ? this.fileKta
                    : this.detailEditAnggota.orang_npa_file,
                orang_kartu_id_file:
                  this.fileKtp != null
                    ? this.fileKtp
                    : this.detailEditAnggota.orang_kartu_id_file,
              });
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Edit data anggota",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
            console.log(error.response);
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
            this.$bvModal.hide("modal-edit-anggota");
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
        console.log(error.response);
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async deleteAnggota(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text: "Data anggota yang sudah dihapus, tidak bisa dikembalikan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, hapus!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.anggota.deleteAnggota(ids.id);
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Menghapus Data Anggota",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async approveDataAnggota(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text:
            "Data User ini akan di approve, pastikan data yang di approve sudah memenuhi persyaratan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Approve!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.anggota.approveUser(ids.orang_npa_idi);
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Approve User",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async sendMessageEmail() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text:
            "Data message ini akan terkirim ke email users, pastikan email,subject dan message yang di isi sudah benar",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Kirim!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.anggota.sendEmail({
                email: this.sendemail.orang_email,
                subject: this.sendemail.subject,
                message: this.sendemail.message,
              });
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Kirim Pesan",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async createPembayaranAnggota() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        var mapDataPembayaran = {
          orang_id: this.getPembayaran.id,
          invoice_name: this.getPembayaran.nama_pembayaran,
          invoice_jumlah: this.getPembayaran.jumlah_pembayaran,
          invoice_status: this.getPembayaran.status_pembayaran,
          email: this.getPembayaran.orang_email,
          nama_lengkap: this.getPembayaran.orang_nama_lengkap,
          title: this.getPembayaran.nama_pembayaran,
          keterangan: this.getPembayaran.keterangan_pembayaran,
        };
        this.$swal({
          title: "Apakah kamu yakin?",
          text: "Data Pembayaran yang dimasukan sudah sesuai?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Create Pembayaran!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.anggota.pembayaran(mapDataPembayaran);
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil create pembayaran",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
            this.clearSelected();
            this.$bvModal.hide("modal-create-pembayaran");
          });
      } catch (error) {
        this.handleMsgErrors(error, "gagal create pembayaran");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async validateAnggotaToPBIDI(data) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        await API.anggota.validateToPBIDI(data.orang_npa_idi);
        this.handleMsgSuccess("Anggota Telah Terdaftar Di PB IDI");
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getAnggota();
  },
  watch: {
    currentPage() {
      this.getAnggota();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
